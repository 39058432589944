import React from 'react'
import { Link } from 'gatsby'
import styles from './legal.module.sass'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import LegalNav from '../../components/legal-nav'

const TermsPage = () => (
   <Layout>
      <SEO title="Terms of Use" />
      <div className={['page', 'page__legal', styles.page__legal].join(' ')}>
         <LegalNav className={styles.legal__nav}></LegalNav>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Terms of Use
            </h1>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               Effective as of April 1, 2020
               <br />
               <br />
               The following document outlines the terms of use for our website.
               <br />
               By using our site, you agree to be bound by these terms.
            </p>
         </div>

         <div className={[styles.legal__content, styles.two_column].join(' ')}>
            <div
               className={[
                  styles.legal__section,
                  styles.table_of_contents,
               ].join(' ')}>
               <div className={styles.toc_inner}>
                  <h3>Table of Contents</h3>
                  <ol>
                     <li>
                        <p>
                           <a href="#terms">Terms</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#use-license">Use License</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#disclaimer">Disclaimer</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#limitations">Limitations</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#accuracy-of-materials">
                              Accuracy of Materials
                           </a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#links">Links</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#no-investment-and-financial-advice">
                              No Investment and Financial Advice
                           </a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#cookies-and-first-party-tracking">
                              Cookies and First-Party Tracking
                           </a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#live-chat">Live Chat</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#website-interactions">
                              Website Interactions
                           </a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#hotjar">Hotjar</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#employment">Employment</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#contact">Contact</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#mailing-list">Mailing List</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#governing-law">Governing Law</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#privacy-policy">Privacy Policy</a>
                        </p>
                     </li>
                     <li>
                        <p>
                           <a href="#contacting-us">Contacting Us</a>
                        </p>
                     </li>
                  </ol>
               </div>
            </div>

            <div className={styles.terms}>
               <a id="terms" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Terms</h3>
                  <p>
                     By accessing the website at{' '}
                     <a
                        href="https://spcfinancial.com"
                        target="_blank"
                        rel="noopener noreferrer">
                        spcfinancial.com
                     </a>
                     , any variation or subdomain of that address, or any of the
                     other websites we own or operate (collectively, the "
                     <b>Site</b>"), you are agreeing to be bound by these Terms
                     of Use (the "<b>Terms</b>") and all applicable laws and
                     regulations. If you do not agree with any of these Terms,
                     you are prohibited from using or accessing our Site. The
                     images and materials contained on our Site are protected by
                     applicable copyright and trademark laws.
                  </p>
                  <p>
                     As used herein, "<b>SPC</b>", "<b>us</b>", "<b>we</b>", and
                     "<b>our</b>" mean SPC Financial, Inc., the owner of the
                     Site.
                  </p>
               </div>

               <a id="use-license" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Use License</h3>
                  <ol type="a">
                     <li>
                        <p>
                           Permission is granted to provisionally copy or
                           download the images and materials (inclusive of
                           content, words, forms, brochures, and information) on
                           our Site (collectively, the "<b>Content</b>") for
                           personal, non-commercial transitory viewing only.
                           This temporary license is not a transfer of any
                           title, right, or interest to you, and you agree that
                           you may not:
                        </p>
                        <ol type="i">
                           <li>
                              <p>
                                 modify, change, alter, or adapt any of the
                                 Content for your personal or commercial use;
                              </p>
                           </li>
                           <li>
                              <p>use the Content for any commercial purpose;</p>
                           </li>
                           <li>
                              <p>
                                 publish, republish, disseminate, distribute,
                                 publicly display, or share any of the Content
                                 for any commercial, non-commercial, or personal
                                 purpose;
                              </p>
                           </li>
                           <li>
                              <p>
                                 attempt to decompile or reverse engineer any
                                 software contained on our Site;
                              </p>
                           </li>
                           <li>
                              <p>
                                 remove any copyright, trademark, or other
                                 proprietary notations from the images and
                                 Content; or
                              </p>
                           </li>
                           <li>
                              <p>
                                 transfer the Content to another person or
                                 "mirror" the Content on a server which is not
                                 owned by SPC.
                              </p>
                           </li>
                        </ol>
                     </li>

                     <li>
                        <p>
                           This license shall be deemed automatically terminated
                           if you violate any of these Terms, with or without
                           notice from us. Upon termination of this license, you
                           hereby agree to delete or destroy all of the Content
                           in your possession, whether in electronic or printed
                           format, whether saved on your computer, in a data
                           storage device, in the cloud, or on another server.
                        </p>
                     </li>
                  </ol>
               </div>

               <a id="disclaimer" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Disclaimer</h3>
                  <ol type="a">
                     <li>
                        <p>
                           The materials on our Site are provided for
                           informational purposes only and are not a complete
                           description and do not comprise a recommendation by
                           us. Some of the materials on our Site have been
                           sourced from information considered to be reliable,
                           but SPC does not represent, warrant, or guarantee
                           that the materials are accurate in all respects. We
                           make no representations or warranties, expressed or
                           implied, and hereby disclaim all other warranties
                           including, without limitation, warranties of fitness
                           for a particular purpose.
                        </p>
                     </li>

                     <li>
                        <p>
                           SPC does not give any warranties or make any
                           representations concerning the accuracy, likely
                           results, or reliability of the use of the Content on
                           our Site or on any websites, resources, or services
                           linked to our Site.
                        </p>
                     </li>
                  </ol>
               </div>

               <a id="limitations" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Limitations</h3>
                  <p>
                     In no event shall SPC, its website host, directors,
                     officers, employees, representatives, agents, or affiliates
                     be liable for any losses, expenses, costs, trading losses,
                     or damages (including but not limited to loss of data, lost
                     profits, or losses not avoided) due to use of the Site or
                     the inability to access the Site. These limitations of
                     liability include the transmission of any viruses which may
                     infect the user’s equipment, as well as any internet
                     connection problems.
                  </p>
               </div>

               <a id="accuracy-of-materials" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Accuracy of Materials</h3>
                  <p>
                     The Content appearing on our Site may contain errors of a
                     substantive, technical, typographical, or inadvertent
                     nature. As previously noted, we do not represent or warrant
                     that all of the Content on our Site are accurate, complete,
                     or even current. While we may make changes to the materials
                     on our Site from time to time without notice, we do not
                     make any commitment to update materials which are not
                     accurate, complete, or current.
                  </p>
               </div>

               <a id="links" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Links</h3>
                  <p>
                     Certain third-party links may allow you to leave our Site.
                     Such externally linked websites, services, or resources are
                     not owned or controlled by SPC, and we are not responsible
                     or liable for the contents of these third-party websites.
                     We provide these third-party links solely for convenience.
                     The inclusion of any link on our Site does not imply an
                     endorsement, sponsorship, verification, or monitoring of
                     the site by SPC. Interaction with or use of any linked
                     website, service, or resource is not covered under these
                     Terms and is at the user's own risk.
                  </p>
               </div>

               <a
                  id="no-investment-and-financial-advice"
                  className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>No Investment and Financial Advice</h3>
                  <p>
                     Nothing contained on this Site constitutes investment
                     advice, financial advice, or the recommendation of any
                     security or product. All content and materials are provided
                     for your convenience and information only, and no such
                     content or materials shall constitute an offer to buy or
                     sell any security.
                  </p>
               </div>

               <a
                  id="cookies-and-first-party-tracking"
                  className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Cookies and First-Party Tracking</h3>
                  <p>
                     We use cookies and/or other technologies when you visit our
                     Site to improve your experience. A “cookie” is a small
                     string of information that a website stores on a user’s
                     computer. These cookies can be either permanent or
                     temporary. Most browsers are set to accept cookies by
                     default; however, you can remove or reject cookies in your
                     browser’s settings. Please be aware that such action could
                     affect the availability and functionality of our Site. For
                     more information on how to control cookies, please check
                     your browser or device’s settings.
                  </p>
               </div>

               <a id="live-chat" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Live Chat</h3>
                  <p>
                     Live chat on our website is provided by LiveChat, Inc. ("
                     <b>LiveChat</b>"). LiveChat is a real- time, online instant
                     messaging software that enables our sites’ visitors to chat
                     with one of our financial advisors through our website.
                     Thus, if you “Chat with an Advisor” on our website, your
                     communications are processed by LiveChat. LiveChat monitors
                     and stores your IP address, browser information, operating
                     system, pages of our website visited, and length of time
                     spent. LiveChat stores this information on our behalf in a
                     pseudonymized user profile to remember your information on
                     your next visit.
                  </p>
               </div>

               <a id="website-interactions" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Website Interactions</h3>
                  <p>
                     Although we utilize technology to monitor how you interact
                     with our Site, we do not participate in behavior-based
                     advertising. This means that we do not collect or share
                     information about your use of our Site to a third-party in
                     order for them to provide advertising products and services
                     tailored to you. We have a legitimate interest in providing
                     the best experience for our clients and prospects.
                  </p>
               </div>

               <a id="hotjar" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Hotjar</h3>
                  <p>
                     We utilize Hotjar in order to better understand our users’
                     needs and to optimize our Site. Hotjar is a technology
                     service that helps us to better understand our users’
                     experience (e.g., how much time they spend on which pages,
                     which links they choose to click, what users do and do not
                     like), enabling us to enhance and maintain our Site with
                     user feedback in mind. Hotjar uses cookies and other
                     technologies to collect data on our users’ behavior and
                     their devices. This includes a device&#39;s IP address
                     (processed during your session and stored in a
                     de-identified form), device screen size, device type
                     (unique device identifiers), browser information,
                     geographic location (country only), and the preferred
                     language used to display our Site. Hotjar stores this
                     information on our behalf in a pseudonymized user profile.
                     Hotjar is contractually forbidden to sell any of the data
                     collected on our behalf. For further details, please see
                     the ‘about Hotjar’ section of {' '}
                     <a
                        href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar"
                        target="_blank"
                        rel="noopener noreferrer">
                        Hotjar's support site
                     </a>
                     .
                  </p>
               </div>

               <a id="employment" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Employment</h3>
                  <p>
                     If you apply for a job posting on our Site, we collect
                     information necessary to process your application as is
                     explicitly denoted in our application form(s). This may
                     include your name, email address, phone, LinkedIn Profile,
                     cover letter, resume, team interests, active licenses,
                     current employment, and any additional information you
                     choose to provide. Submitting an application is your
                     implicit consent to our collection of this information.
                  </p>
               </div>

               <a id="contact" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Contact</h3>
                  <p>
                     If you contact us through a form or link on our website, we
                     collect information necessary to process your request. This
                     may include your name, email address, phone, preferred time
                     of day for response, and message or request. Contacting us
                     is your implicit consent to our collection of this
                     information.
                  </p>
               </div>

               <a id="mailing-list" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Mailing List</h3>
                  <p>
                     If you request us to send you our weekly financial recap,
                     also known as <em>Current Trends &amp; News</em> ("
                     <b>CTN</b>"), we collect information necessary to process
                     your request. This includes your name and email address. If
                     you request our CTN be mailed, we collect your address. CTN
                     delivery can be stopped by following the unsubscribe
                     instructions in our CTN emails. or by contacting our office
                     by email or phone. We never share our mailing lists with
                     any third-party.
                  </p>
               </div>

               <a id="governing-law" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Governing Law</h3>
                  <p>
                     These Terms of Use are governed by and construed in
                     accordance with the laws of the State of Maryland without
                     giving effect to principles of conflicts of law. Venue and
                     jurisdiction for any legal action or proceeding are proper
                     in the Circuit Court for Montgomery County, Maryland or the
                     United States District Court of Maryland, Greenbelt
                     Division.
                  </p>
               </div>

               <a id="privacy-policy" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Privacy Policy</h3>
                  <p>
                     Your privacy is important to us. Please review our Privacy
                     Policy at{' '}
                     <Link to="/legal/privacy">
                        spcfinancial.com/legal/privacy
                     </Link>
                     .
                  </p>
               </div>

               <a id="contacting-us" className={styles.anchor}></a>
               <div className={styles.legal__section}>
                  <h3>Contacting Us</h3>
                  <p>
                     If you have questions about these Terms or a complaint
                     about the Site, we can be reached electronically via our
                     Site’s contact form(s) or by written correspondence sent
                     to:
                  </p>
                  <div className={styles.legal__contact_address}>
                     <p>
                        <b>SPC Financial, Inc.</b>
                        <br />
                        Attn: Legal Department
                        <br />
                        3202 Tower Oaks Blvd.
                        <br />
                        Suite 400
                        <br />
                        Rockville, MD 20852, United States
                     </p>
                  </div>
               </div>

               <div className={styles.legal__section}>
                  <p>These Terms of Use are effective as of April 1, 2020.</p>
               </div>
            </div>
         </div>
      </div>
   </Layout>
)

export default TermsPage
